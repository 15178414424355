.perlengkapan-shadow{
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    background: #FFFFFF;
    border-radius: 16px;
}

.perlengkapan-card {
    background: linear-gradient(200.57deg, rgba(249, 242, 24, 0.16) 3.37%, rgba(226, 162, 70, 0.16) 86.36%);
    border-bottom: 1px solid rgba(64, 72, 82, 0.1);
    border-radius: 8px 8px 8px 8px;
}

.container-switch-perlengkapan{
    .ant-switch-checked {
        background: #e82320 !important
      }

}

