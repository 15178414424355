.is-sticky {
  position: fixed;
  top: 10px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.roomfilter-list {
    @apply cursor-pointer
}

.roomfilter-list:nth-child(odd) {
    @apply bg-white
}

.roomfilter-list:nth-child(even) {
    @apply bg-red-5
}

.table-roomlist .ant-table-body {
    @apply !overflow-y-auto !max-h-[calc(100vh-265px)]
}

.table-roomlist .ant-table-body::-webkit-scrollbar:horizontal {
    display: none;
}

  /* Scrollbar */
.table-roomlist .ant-table-body::-webkit-scrollbar {
    width: 4px;
}

/* Scrollbar Track */
.table-roomlist .ant-table-body::-webkit-scrollbar-track {
    width: 4px;
    border-right: 10px solid #fff;
    border-left: 10px solid #fff;
}

/* Scrollbar Thumb */
.table-roomlist .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    width: 4px;
    border-radius: 16px;
}

/* Scrollbar Track Pieces */
.table-roomlist .ant-table-body::-webkit-scrollbar-track-piece:start {
    background: #fff;
}

.table-roomlist .ant-table-body::-webkit-scrollbar-track-piece:end {
    background: #fff;
}



.table-roomlist-selected .ant-table-body {
    @apply !overflow-y-auto !max-h-[calc(100vh-333px)]
}

.table-roomlist-selected .ant-table-body::-webkit-scrollbar:horizontal {
    display: none;
}

  /* Scrollbar */
.table-roomlist-selected .ant-table-body::-webkit-scrollbar {
    width: 4px;
}

/* Scrollbar Track */
.table-roomlist-selected .ant-table-body::-webkit-scrollbar-track {
    width: 4px;
    border-right: 10px solid #fff;
    border-left: 10px solid #fff;
}

/* Scrollbar Thumb */
.table-roomlist-selected .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    width: 4px;
    border-radius: 16px;
}

/* Scrollbar Track Pieces */
.table-roomlist-selected .ant-table-body::-webkit-scrollbar-track-piece:start {
    background: #fff;
}

.table-roomlist-selected .ant-table-body::-webkit-scrollbar-track-piece:end {
    background: #fff;
}




/* .table-roomlist .ant-table-thead td {
    @apply !border-t !border-b border-solid border-red-500
} */