.react-datepicker-wrapper {
    @apply !w-full
}

.react-datepicker__view-calendar-icon {
    @apply relative
}

.react-datepicker__input-container input {
    @apply !bg-gray-5 !rounded-xl outline-none 
    !p-4 text-sm !m-0 cursor-pointer hover:!bg-red-5 focus:!bg-red-5 !w-full
}

.react-datepicker__calendar-icon {
    @apply !p-0 right-4 top-[25%] 
}

.react-datepicker-popper .react-datepicker {
    @apply !w-full !rounded-2xl !border-0 shadow-custom-1 !text-xs
}

.react-datepicker__header {
    @apply bg-white !rounded-2xl pt-4 pr-4 pl-4 pb-0 border-0
}

.react-datepicker__triangle {
    @apply !hidden
}

.react-datepicker__current-month {
    @apply mb-3 text-xs
}

.react-datepicker__day-names {
    @apply border-t py-2
}

.react-datepicker__navigation {
    @apply !top-[12px] !w-6 !h-6
}

.react-datepicker__navigation--previous {
    @apply !left-3
}

.react-datepicker__navigation--next {
    @apply !right-3
}

/* .react-datepicker__navigation-icon {
    @apply !w-6 !h-6
} */

.react-datepicker__navigation-icon::before {
    @apply !border-black !w-2 !h-2 !border-t-2 !border-r-2
}

.react-datepicker__month {
    @apply !mb-4
}

.react-datepicker__day {
    @apply hover:!bg-red-5
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    @apply !bg-red-4 !text-black !font-normal
}

.react-datepicker__day--outside-month {
    @apply !text-[#BDBDBD] 
}

.react-datepicker__time-list

.react-datepicker__time-list::-webkit-scrollbar:horizontal {
    display: none;
}
  /* Scrollbar */
.react-datepicker__time-list::-webkit-scrollbar {
width: 4px;
}

/* Scrollbar Track */
.react-datepicker__time-list::-webkit-scrollbar-track {
width: 4px;
border-right: 10px solid #fff;
border-left: 10px solid #fff;
}

/* Scrollbar Thumb */
.react-datepicker__time-list::-webkit-scrollbar-thumb {
background-color: #e0e0e0;
width: 4px;
border-radius: 16px;
}

/* Scrollbar Track Pieces */
.react-datepicker__time-list::-webkit-scrollbar-track-piece:start {
background: #fff;
}

.react-datepicker__time-list::-webkit-scrollbar-track-piece:end {
    background: #fff;
}

.react-datepicker__header--time--only {
    @apply !p-2
}

.react-datepicker-time__header {
    @apply !text-xs
}

.react-datepicker__time-list-item  {
    @apply hover:!bg-red-5
}

.react-datepicker__time-list-item--selected {
    @apply !bg-red-4 !text-black !font-normal
}