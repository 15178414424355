.rows:hover .action {
  position: absolute;
  /* background-color: #fbf3f2; */
  opacity: 1;
}

.rows:hover .action .action-content {
  position: absolute;
  right: 0;
  padding: 0 ;
  display: flex;
  height: 100%;
  align-items: center;
}


.action {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  /* display: flex; */
  justify-content: end;
  align-items: center;
  inset: 0;
  gap: 2rem;
  right:0px;
  /* margin-right: 2rem; */
  padding: 0px 2rem;
}

.action .action-content .action-content-item {
  cursor: pointer;
  padding: 0 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-dot-item {
  background-color: red !important;
}
