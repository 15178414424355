
.ant-drawer-wrapper-body {
    width: 540px;
}
.cfTTeO .ant-drawer-content-wrapper{
    width: 540px !important;

}
.data-html{
    margin-left: 0px;
    ul {
       margin-left: 16px;
        li {
            margin:0px;
            padding:0px;
            list-style-type:disc;
          
        }

    }

    ol {
        margin-left: 16px;
        li {
            margin:0px;
            padding:0px;
            list-style-type:decimal;
        }
        
    }
    
}